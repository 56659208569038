class InfoWindow extends window.google.maps.OverlayView {
    constructor() {
        super();
        this.content = '';

        this.pixelOffset = document.createElement('div');
        this.pixelOffset.classList.add('popup-bubble-anchor');

        this.anchor = document.createElement('div');
        this.anchor.classList.add('popup-tip-anchor');
        this.anchor.appendChild(this.pixelOffset);
        this.stopEventPropogation();
    }

    onAdd() {
        this.getPanes().floatPane.appendChild(this.anchor);
    }

    onRemove() {
        if (this.anchor.parentElement) {
            this.anchor.parentElement.removeChild(this.anchor);
        }
    }

    draw() {
        let divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
        // Hide the popup when it is far out of view.
        let display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';
        if (divPosition.y < 70) {
            this.pixelOffset.classList.remove('above');
            this.pixelOffset.classList.add('below');
        } else {
            this.pixelOffset.classList.remove('below');
            this.pixelOffset.classList.add('above');
        }

        if (display === 'block') {
            this.anchor.style.left = divPosition.x + 'px';
            this.anchor.style.top = divPosition.y + 'px';
        }
        if (this.anchor.style.display !== display) {
            this.anchor.style.display = display;
        }
    }

    setContent(content) {
        if (typeof this.content === 'object') {
            this.pixelOffset.removeChild(this.content);
        }
        content.classList.add('popup-bubble-content');

        if (document.ExperimentId && parseInt(document.ExperimentId, 10) === 8) content.classList.add('red-arrow');
        this.pixelOffset.appendChild(content);
        this.content = content;
    }

    open(map, pin) {
        if (this.isOpen) {
            this.close();
        }
        this.isOpen = true;
        // console.log('pin position for window', pin.getPosition());
        this.position = pin.getPosition();
        this.setMap(map);
    }

    close() {
        this.isOpen = false;
        this.setMap(null);
    }

    stopEventPropogation() {
        let anchor = this.anchor;
        anchor.style.cursor = 'auto';
        window.google.maps.OverlayView.preventMapHitsFrom(anchor);
    }
}

export default InfoWindow;

import React, { Component } from 'react';
import '../../style/views/parts/Header.css';

class Header extends Component {
    render() {
        const { order, width, activeMode, listClick, mapClick } = this.props;
        const showMobile = width < 750;

        // if we don't have a placeId, don't render the header because it shows behind the map
        if (!this.props.order.placeId) return null;
        if (!showMobile) {
            return null;
        }
        return (
            <div className="slide-out-header jared">
                <div className="tab-container">
                    <div
                        className={`map-view ${activeMode}`}
                        onClick={() => {
                            mapClick();
                        }}>
                        Map View
                    </div>
                    <div
                        className={`list-view ${activeMode}`}
                        onClick={() => {
                            listClick();
                        }}>
                        List View
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;

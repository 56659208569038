import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../style/map/Map.css';

class Map extends Component {
    map_recenter = (latlng, offsetx, offsety) => {
        const { google } = this.props;
        const point1 = this.map
            .getProjection()
            .fromLatLngToPoint(latlng instanceof google.maps.LatLng ? latlng : this.map.getCenter());
        const point2 = new google.maps.Point(
            (typeof offsetx == 'number' ? offsetx : 0) / Math.pow(2, this.map.getZoom() + 1) || 0,
            (typeof offsety == 'number' ? offsety : 0) / Math.pow(2, this.map.getZoom() + 1) || 0,
        );
        return this.map
            .getProjection()
            .fromPointToLatLng(new google.maps.Point(point1.x - point2.x, point1.y + point2.y));
    };

    componentDidUpdate(prevProps) {
        const {
            google,
            bounds,
            width,
            focusedVendor,
            vendors,
            hoverVendorTile,
            setTargetVendor,
            focusTargetVendor,
            center,
        } = this.props;
        if (bounds && !prevProps.bounds) {
            setTimeout(() => {
                if (this.getMarkerCount() < 30) {
                    if (width > 750) {
                        this.map.fitBounds(bounds, { right: 464, top: 45 });
                    } else {
                        this.map.fitBounds(bounds, { top: 185 });
                    }
                } else {
                    this.map.setZoom(11);
                    if (width > 750) {
                        this.map.panTo(
                            this.map_recenter(new google.maps.LatLng(center.lat, center.lng), -464, -80),
                        );
                    } else {
                        this.map.panTo(this.map_recenter(new google.maps.LatLng(center.lat, center.lng), 0, -80));
                    }
                }
                if (this.map.getZoom() > 15) {
                    this.map.setZoom(15);
                }
            }, 200);
        }

        if (focusedVendor && focusedVendor !== prevProps.focusedVendor && hoverVendorTile) {
            //focused vendor has changed, and we have one for sure
            const vendor = vendors[vendors.placeId].itemsById[focusedVendor];
            if (width > 750) {
                this.map.panTo(this.map_recenter(new google.maps.LatLng(vendor.lat, vendor.lng), -464, 0));
            } else {
                this.map.panTo(new google.maps.LatLng(vendor.lat, vendor.lng));
            }
        }
        if (!!vendors.centermostVendor && this.firstLoad && width > 750) {
            setTimeout(() => {
                setTargetVendor(vendors.centermostVendor);
                focusTargetVendor(vendors.centermostVendor);
            }, 1500);
            this.firstLoad = false;
        }
    }

    componentDidMount() {
        const { google } = this.props;
        if (google) {
            this.loadMap();
        }
        this.firstLoad = true;
    }

    getMapStyle() {
        //const textFillColor = "#444444";
        //const textStrokeColor = "#f2f2f2";
        const roadColor = '#eeeeee';
        const highwayColor = '#ffe82d';
        const waterColor = '#abd6e4';
        return [
            { elementType: 'geometry', stylers: [{ color: '#fffcf5' }] },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{ color: '#c8edb9' }],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: roadColor }],
            },
            {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [{ color: roadColor }],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{ color: highwayColor }],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [{ color: '#ffca4c' }],
            },
            {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [{ color: '#ffe29f' }],
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: waterColor }],
            },
        ];
    }

    getMarkerCount() {
        const { markers } = this.props;
        return Object.keys(markers).length;
    }

    loadMap() {
        const { unfocusVendor, bounds, width } = this.props;
        if (this.props && this.props.google) {
            const { google } = this.props;
            const maps = google.maps;
            const mapRef = this.refs.map;
            const node = ReactDOM.findDOMNode(mapRef);
            const { center } = this.props;
            const zoom = center ? 12 : 4;
            const { lat, lng } = center ? center : { lat: 37.0902, lng: -95.7129 };
            const mapCenter = new maps.LatLng(lat, lng);
            const style = this.getMapStyle();
            const mapConfig = Object.assign(
                {},
                {
                    center: mapCenter,
                    zoom,
                    disableDefaultUI: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    draggable: true,
                    styles: style,
                },
            );
            this.map = new maps.Map(node, mapConfig);
            this.map.addListener('click', (e) => {
                unfocusVendor();
            });
            if (bounds) {
                setTimeout(() => {
                    if (width > 750) {
                        this.map.fitBounds(bounds, { right: 464, top: 45 });
                    } else {
                        this.map.fitBounds(bounds, { top: 115 });
                    }
                    if (this.map.getZoom() > 15) {
                        this.map.setZoom(15);
                    }
                }, 200);
            }
            this.forceUpdate();
        }
    }

    scrollToVendor = (vendorId) => {
        const vendorElement = document.getElementById(`vendor-tile-${vendorId}`);
        const vendorListElement = document.getElementById('vendor-list'); // Make sure this is your vendor list container

        if (vendorElement && vendorListElement) {
            vendorListElement.scrollTo({
                top: vendorElement.offsetTop - vendorListElement.offsetTop - 20,
                behavior: 'smooth',
            });
        }
    };

    renderChildren() {
        const { children, selectedPin, google } = this.props;

        if (!children || !this.map) return;
        const totalTime = 1000;
        const timePerChild = totalTime / children.length;
        let delay = 0;
        let pinIndex = 0;
        return React.Children.map(children, (c) => {
            delay = pinIndex === 0 ? delay : delay + timePerChild;
            pinIndex++;
            return React.cloneElement(c, {
                map: this.map,
                google: google,
                delay,
                scroll: this.scrollToVendor,
                selectedPin: selectedPin,
            });
        });
    }

    render() {
        const { height, showSlideOut } = this.props;
        return (
            <div
                ref={'map'}
                className={`map zip-selector-map${showSlideOut ? ' show-list' : ''}`}
                style={{
                    height: `${height}px`,
                }}>
                Loading Map...
                {this.renderChildren()}
            </div>
        );
    }
}

Map.propTypes = {
    google: PropTypes.object,
    regionSelected: PropTypes.string,
    height: PropTypes.number,
    markers: PropTypes.object,
    center: PropTypes.object,
};
export default Map;

import fetch from 'cross-fetch';
import * as geoUtils from '../misc/geo/GeoUtils';
import * as handoffHelper from '../util/HandoffHelper';
import * as actionTypes from './actionTypes';

/*==========================================================
                     Interface Actions
============================================================ */

export const resizeWindow = (width, height) => {
    return {
        type: actionTypes.RESIZE_WINDOW,
        width,
        height,
    };
};

export const updateHistory = (history) => {
    return {
        type: actionTypes.UPDATE_HISTORY,
        history,
    };
};

export const setReady = () => {
    return {
        type: actionTypes.SET_READY,
    };
};

export const setNotReady = () => {
    return {
        type: actionTypes.SET_NOT_READY,
    };
};

export const setDepth = (depth) => {
    return {
        type: actionTypes.SET_DEPTH,
        depth,
    };
};

export const setBlurOnly = () => {
    return {
        type: actionTypes.SET_BLUR_ONLY,
    };
};

export const aboutClick = (service) => {
    return {
        type: actionTypes.ABOUT_CLICK,
        service,
    };
};

export const aboutClear = () => {
    return {
        type: actionTypes.ABOUT_CLEAR,
    };
};

export const showSlideOut = () => {
    return {
        type: actionTypes.SHOW_SLIDE_OUT,
    };
};

export const hideSlideOut = () => {
    return {
        type: actionTypes.HIDE_SLIDE_OUT,
    };
};

export const showModifierDrawer = (features) => {
    return {
        type: actionTypes.SHOW_MODIFIER_DRAWER,
        features,
    };
};

export const hideModifierDrawer = () => {
    return {
        type: actionTypes.HIDE_MODIFIER_DRAWER,
    };
};

export const throwError = (error) => {
    return {
        type: actionTypes.THROW_ERROR,
        error,
    };
};

export const clearError = () => {
    return {
        type: actionTypes.CLEAR_ERROR,
    };
};

export const setZip = (zip) => {
    return {
        type: actionTypes.SET_ZIP,
        zip,
    };
};

export const slideOutExtend = () => {
    return {
        type: actionTypes.SLIDE_OUT_EXTEND,
    };
};

export const slideOutRetract = () => {
    return {
        type: actionTypes.SLIDE_OUT_RETRACT,
    };
};

const startAltServiceFetch = () => {
    return {
        type: actionTypes.START_ALT_SERVICE_FETCH,
    };
};

const stopAltServiceFetch = () => {
    return {
        type: actionTypes.STOP_ALT_SERVICE_FETCH,
    };
};

export const setSearchInputPulsing = (isInputPulsing) => {
    return {
        type: actionTypes.SET_SEARCH_INPUT_PULSE,
        isInputPulsing: isInputPulsing,
    };
};

export const setSearchButtonPulsing = (isButtonPulsing) => {
    return {
        type: actionTypes.SET_SEARCH_BUTTON_PULSE,
        isButtonPulsing: isButtonPulsing,
    };
};
/*==========================================================
                       Map Actions
============================================================ */

export const setMapCenter = (center, placeId) => {
    return {
        type: actionTypes.SET_MAP_CENTER,
        center,
        placeId,
    };
};

const _setMarkers = (markers) => {
    return {
        type: actionTypes.SET_MARKERS,
        markers,
    };
};

export const clearMap = () => {
    return {
        type: actionTypes.CLEAR_MAP,
    };
};

/*==========================================================
                     Location Lookup Actions
============================================================ */
export const fetchLocationName = (id) => {
    return {
        type: actionTypes.FETCH_LOC_NAME,
    };
};

export const receiveLocationName = (name) => {
    return {
        type: actionTypes.RECEIVE_LOC_NAME,
        canonicalName: name,
    };
};

export const errorLocationName = () => {
    return {
        type: actionTypes.ERROR_LOC_NAME,
    };
};

export const fetchLocationLatLng = (name) => {
    return {
        type: actionTypes.FETCH_LOC_LAT_LNG,
    };
};

export const receiveLocationLatLng = (json) => {
    return {
        type: actionTypes.RECEIVE_LOC_LAT_LNG,
        lat: json.lat,
        lng: json.lng,
    };
};

export const showLocationErrorMessage = (message) => {
    return {
        type: actionTypes.SHOW_LOCATION_ERROR_MESSAGE,
        msg: message,
    };
};

export const hideLocationErrorMessage = () => {
    return {
        type: actionTypes.HIDE_LOCATION_ERROR_MESSAGE,
    };
};

/*==========================================================
                     Vendor Actions
============================================================ */

export const requestVendors = (placeId) => {
    return {
        type: actionTypes.REQUEST_VENDORS,
        placeId,
    };
};

export const receiveVendors = (placeId, all, standard, centermostVendor) => {
    return {
        type: actionTypes.RECEIVE_VENDORS,
        placeId,
        vendors: all,
        standard,
        centermostVendor,
        receivedAt: Date.now(),
    };
};

export const setTargetVendor = (vendorId, isPin) => {
    return {
        type: actionTypes.SET_TARGET_VENDOR,
        vendorId,
        isPin,
    };
};

export const focusTargetVendor = () => {
    return {
        type: actionTypes.FOCUS_TARGET_VENDOR,
    };
};

export const unfocusVendor = () => {
    return {
        type: actionTypes.UNFOCUS_VENDOR,
    };
};

export const hoverVendorTile = () => {
    return {
        type: actionTypes.HOVER_VENDOR_TILE,
    };
};

export const clearVendorTile = () => {
    return {
        type: actionTypes.CLEAR_VENDOR_TILE,
    };
};

export const showPreferredVendors = () => {
    return {
        type: actionTypes.SHOW_PREFERRED_VENDORS,
    };
};

export const showAllVendors = () => {
    return {
        type: actionTypes.SHOW_ALL_VENDORS,
    };
};

/*==========================================================
                     Kingdom Actions
============================================================ */

export const requestKingdoms = () => {
    return {
        type: actionTypes.REQUEST_KINGDOMS,
    };
};

export const receiveKingdoms = (json) => {
    return {
        type: actionTypes.RECEIVE_KINGDOMS,
        kingdoms: json,
        receivedAt: Date.now(),
    };
};

/*==========================================================
                     Phylum Actions
============================================================ */

export const requestPhyla = (kingdomId) => {
    return {
        type: actionTypes.REQUEST_PHYLA,
        kingdomId,
    };
};

export const receivePhyla = (kingdomId, json) => {
    return {
        type: actionTypes.RECEIVE_PHYLA,
        kingdomId,
        phyla: json,
        receivedAt: Date.now(),
    };
};

/*==========================================================
                    Family Actions
============================================================ */

export const requestFamilies = (phylumId) => {
    return {
        type: actionTypes.REQUEST_FAMILIES,
        phylumId,
    };
};

export const receiveFamilies = (phylumId, json) => {
    return {
        type: actionTypes.RECEIVE_FAMILIES,
        phylumId,
        families: json,
        receivedAt: Date.now(),
    };
};

/*==========================================================
                     Order Actions
============================================================ */

export const selectVendor = (vendor) => {
    return {
        type: actionTypes.SELECT_VENDOR,
        vendor,
    };
};

export const confirmVendor = () => {
    return {
        type: actionTypes.CONFIRM_VENDOR,
    };
};

export const setVendorInsufficient = () => {
    return {
        type: actionTypes.SET_VENDOR_INSUFFICIENT,
    };
};

export const clearSelectedVendor = () => {
    return {
        type: actionTypes.CLEAR_SELECTED_VENDOR,
    };
};

export const selectKingdom = (id, kingdomSelectedFromDocument) => {
    return {
        type: actionTypes.SELECT_KINGDOM,
        id,
        kingdomSelectedFromDocument,
    };
};

export const useDocumentKingdom = () => {
    return {
        type: actionTypes.USE_DOCUMENT_KINGDOM,
    };
};

export const clearSelectedKingdom = () => {
    return {
        type: actionTypes.CLEAR_SELECTED_KINGDOM,
    };
};

export const selectPhylum = (id) => {
    return {
        type: actionTypes.SELECT_PHYLUM,
        id,
    };
};

export const clearSelectedPhylum = () => {
    return {
        type: actionTypes.CLEAR_SELECTED_PHYLUM,
    };
};

export const selectService = (service) => {
    return {
        type: actionTypes.SELECT_SERVICE,
        service,
    };
};

export const clearSelectedService = () => {
    return {
        type: actionTypes.CLEAR_SELECTED_SERVICE,
    };
};

export const clearOrder = () => {
    return (dispatch) => {
        dispatch(clearSelectedVendor());
        dispatch(clearSelectedKingdom());
        dispatch(clearSelectedPhylum());
        dispatch(clearSelectedService());
    };
};

export const initFeature = (feature) => {
    return {
        type: actionTypes.INIT_FEATURE,
        feature,
    };
};

export const updateFeatureOption = (feature, option) => {
    return {
        type: actionTypes.UPDATE_FEATURE_OPTION,
        feature,
        option,
    };
};

export const addFeatureOption = (feature, option) => {
    return {
        type: actionTypes.ADD_FEATURE_OPTION,
        feature,
        option,
    };
};

export const removeFeatureOption = (feature, option) => {
    return {
        type: actionTypes.REMOVE_FEATURE_OPTION,
        feature,
        option,
    };
};

/*==========================================================
                     Fetching Actions
      impure functions using redux-thunk which typically
         asynchronously call actions defined above
============================================================ */

/*==========================================================
                     Map Thunk Actions
============================================================ */

export const setMarkers = (vendorSet) => {
    return (dispatch) => {
        let markers = {};
        vendorSet.forEach((vendor) => {
            markers[vendor.vendorId] = {
                lat: vendor.lat,
                lng: vendor.longitude,
            };
        });
        dispatch(_setMarkers(markers));
    };
};

/*==========================================================
                 Vendor Thunk Actions
============================================================ */
/**
 * Fetches Vendors available in the area. This function doesn't care about services or features.
 * Closer to release, this should be changed to use a backend script that simnply searches filemaker using latlng bounds. For now, use center and valid vendor update
 *
 * Commented out on 2/27/2019 as part of warning elimination.
 *
 * @param latLng
 * @param placeId
 * @returns {Function}
 */
/*const applyHandicap = vendor => {
	switch (vendor.siteType) {
		case "Q":
			vendor["distance"] = vendor.distance + constants.questHandicap;
			break;
		case "QPF":
			vendor["distance"] = vendor.distance + constants.qpfHandicap;
			break;
		case "E":
			vendor["distance"] = vendor.distance + constants.eScreenHandicap;
			break;
		case "E2":
			vendor["distance"] = vendor.distance + constants.eScreenHandicap + 2.5;
			break;
		case "E3":
			vendor["distance"] = vendor.distance + constants.eScreenHandicap + 5;
			break;
		case "EQ":
			vendor["distance"] = vendor.distance + constants.questHandicap;
			break;
		case "TP":
			vendor["distance"] = vendor.distance + constants.tpHandicap;
			break;
		default:
			break;
	}
};*/

export const fetchVendorsWithoutService = (placeId, bounds, isSearch = false) => {
    return (dispatch, getState) => {
        if (shouldFetchVendorsWithoutService(getState(), placeId, isSearch)) {
            dispatch(clearMap());
            dispatch(hideSlideOut());
            dispatch(setNotReady());
            return dispatch(_fetchVendorsWithoutService(bounds, placeId));
        } else {
            return Promise.resolve();
        }
    };
};

const shouldFetchVendorsWithoutService = (state, placeId, isSearch = false) => {
    if (isSearch) {
        return true;
    }

    const vendors = state.vendors[placeId];
    if (!vendors) {
        return true;
    } else if (vendors.isFetching) {
        return false;
    } else {
        return vendors.didInvalidate;
    }
};

const _fetchVendorsWithoutService = (bounds, placeId) => {
    return function (dispatch, getState) {
        dispatch(requestVendors(placeId));

        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();
        const centerLat = (southWest.lat() + northEast.lat()) / 2;
        const centerLng = (southWest.lng() + northEast.lng()) / 2;

        let smallestDistance = {
            vendorId: null,
            distance: Number.POSITIVE_INFINITY,
        };
        let url = `https://app.health-street.net/api/v1/vendors/query/vendor?key=MjIxNzI4RjQtM0RFRi00RjAzLUFGNzYtNEQ5NTRFNTJGMEY2&lat=${southWest.lat()}&lng=${southWest.lng()}&lat2=${northEast.lat()}&lng2=${northEast.lng()}`;
        const productId = handoffHelper.getDefaultProductIdForKingdomVendors();
        console.log('🚀 ~ productId:', productId);
        if (productId) url += `&service=${productId}`;

        return fetch(url)
            .then(
                (response) => response.json(),
                (error) => dispatch(throwError(error)),
            )
            .then((json) => {
                //iterate over the results and see if we have at least 5 "preferred" vendors
                let standardVendors = [];
                json.forEach((vendor) => {
                    let distanceToCenter = geoUtils.getDistance(vendor.lat, vendor.lng, centerLat, centerLng);
                    if (distanceToCenter < smallestDistance.distance) {
                        smallestDistance.vendorId = vendor.vendorId;
                        smallestDistance.distance = distanceToCenter;
                    }

                    standardVendors.push(vendor);
                });
                standardVendors = standardVendors.sort((a, b) => {
                    return a.siteTypeNumber - b.siteTypeNumber;
                });
                const centermostVendor = smallestDistance.vendorId;

                dispatch(setMarkers(standardVendors));
                dispatch(receiveVendors(placeId, json, standardVendors, centermostVendor));

                appendPlaceIdToDrugCardLinks(placeId);
            });
    };
};

export const fetchVendorsWithService = () => {
    return (dispatch, getState) => {
        const state = getState();
        const placeId = state.vendors.placeId;
        const latLng = {
            lat: state.order.selectedVendor.lat,
            lng: state.order.selectedVendor.lng,
        };
        const service = state.order.selectedService.id;
        dispatch(clearMap());
        dispatch(setNotReady());
        dispatch(hideSlideOut());
        const selectedFeatures = state.order.selectedFeatures[service];
        let bodyHair = 0;
        let realService = service;
        const featureChecker = (option) => {
            if (state.optionsById[option].bodyHair) {
                bodyHair = 1;
            }
            if (state.optionsById[option].swapVendorSets) {
                realService = state.optionsById[option].serviceId;
            }
        };
        for (let key in selectedFeatures) {
            if (selectedFeatures.hasOwnProperty(key)) {
                if (state.featuresById[key].bodyHair || state.featuresById[key].swapVendorSets) {
                    selectedFeatures[key].forEach(featureChecker);
                }
            }
        }

        appendPlaceIdToDrugCardLinks(placeId);
        return dispatch(_fetchVendorsWithService(latLng, service, realService, bodyHair, placeId));
    };
};
const _fetchVendorsWithService = (latLng, service, realService, bodyHair, placeId) => {
    return (dispatch) => {
        dispatch(requestVendors(placeId));

        let url = `https://app.health-street.net/api/v1/vendors/query/vendor?key=MjIxNzI4RjQtM0RFRi00RjAzLUFGNzYtNEQ5NTRFNTJGMEY2&service=${realService}&bh=${bodyHair}&lat=${latLng.lat}&lng=${latLng.lng}`;
        const productId = handoffHelper.getDefaultProductIdForKingdomVendors();
        if (productId) url += `&service=${productId}`;

        return fetch(url)
            .then(
                (response) => response.json(),
                (error) => dispatch(throwError(error)),
            )
            .then((json) => {
                let preferredVendors = [];
                let platinumVendors = [];
                let standardVendors = [];
                json.forEach((vendor) => {
                    if (vendor.siteTypeNumber < 2) {
                        platinumVendors[platinumVendors.length] = vendor;
                    } else if (vendor.siteTypeNumber < 3) {
                        preferredVendors[preferredVendors.length] = vendor;
                    } else {
                        standardVendors[standardVendors.length] = vendor;
                    }
                });
                if (platinumVendors.length > 4) {
                    //we have at least 5 platinum vendors! let's only show platinums.
                    dispatch(setMarkers(platinumVendors));
                } else if (platinumVendors.concat(preferredVendors).length > 4) {
                    //we have at least 5 platinum+preferred vendors. lets show platinum and preferred.
                    dispatch(setMarkers(platinumVendors.concat(preferredVendors)));
                } else {
                    //we don't have at least 5 preferred vendors, go ahead and flip the switch to show them all.
                    dispatch(showAllVendors());
                    dispatch(setMarkers(platinumVendors.concat(preferredVendors, standardVendors)));
                }
                standardVendors = standardVendors.sort((a, b) => {
                    return a.siteTypeNumber - b.siteTypeNumber;
                });
                dispatch(receiveVendors(placeId, json, standardVendors, preferredVendors, platinumVendors));
                dispatch(setReady());
            });
    };
};

const appendPlaceIdToDrugCardLinks = (placeId) => {
    var drugCards = document.querySelectorAll('.drug-test-card');
    for (let i = 0; i < drugCards.length; i++) {
        let url = new URL(drugCards[i].href);
        let params = new URLSearchParams(url.search.slice(1));

        // replace / add zip param to url
        params.delete('zip');
        params.append('zip', placeId.replace(/, /g, '+'));
        url.search = '?' + params.toString();

        // put the new url onto the actual DOM nodes
        drugCards[i].href = url.href;
    }
};

/*==========================================================
                Location Lookup Thunk Actions
============================================================ */

export const fetchLocationCanonicalNameFromId = (id) => {
    return (dispatch) => {
        // TODO check if we should do lookup
        dispatch(_fetchLocationCanonicalNameFromId(id));
    };
};

const _fetchLocationCanonicalNameFromId = (id) => {
    return (dispatch) => {
        dispatch(fetchLocationName(id));

        // determine search engine source
        var source = '';
        if (window.location.href.indexOf('gclid') > -1) {
            source = 'google';
        } else if (window.location.href.indexOf('msclkid') > -1) {
            source = 'bing';
        } else {
            // assume default is google
            source = 'google';
        }
        return fetch(
            'https://psyugb5oo8.execute-api.us-east-1.amazonaws.com/prod/?criteriaId=' + id + '&source=' + source,
        )
            .then(
                (response) => response.json(),
                (error) => console.log('error fetching location name for id=' + id),
            )
            .then((json) => {
                if (!json.Item) {
                    console.error('Unable to load location from adwords id.  id=' + id);
                    dispatch(errorLocationName());
                    return;
                }

                // process response
                let returnedNameString = json.Item.canonicalName.S;
                let canonicalNameParts = returnedNameString.replace(/\|/g, ',').split(',');
                let canonicalName = canonicalNameParts[0] + ', ' + canonicalNameParts[1];

                // forward response
                dispatch(receiveLocationName(canonicalName));
            });
    };
};

export const fetchLocationLatLngFromCanonicalName = (name, google) => {
    return (dispatch) => {
        dispatch(_fetchLocationLatLngFromCanonicalName(name, google));
    };
};

const _fetchLocationLatLngFromCanonicalName = (name, google) => {
    return (dispatch, getState) => {
        // TODO FIXME validate the name for invalid characters

        dispatch(fetchLocationLatLng());

        const geocoder = new google.maps.Geocoder();
        let address = name.replace(/, /g, '+');
        address = address.replace(/\|/g, '+');
        geocoder.geocode({ address: address }, (results, status) => {
            if (status === 'OK') {
                const latLng = results[0].geometry.location;
                dispatch(receiveLocationLatLng(latLng));
                dispatch(setMapCenter(latLng, getState().locationLookup.canonicalName));
                dispatch(
                    fetchVendorsWithoutService(
                        getState().locationLookup.canonicalName,
                        geoUtils.getRadiusBoundingBox(latLng, 20, 20),
                    ),
                );
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
                return;
            }
        });
    };
};

export const showLocErrorMsg = (message) => {
    return (dispatch, getState) => {
        dispatch(showLocationErrorMessage(message));

        setTimeout(function () {
            dispatch(hideLocationErrorMessage());
        }, 4000);
    };
};

export const hideLocErrorMsg = () => {
    return (dispatch, getState) => {
        dispatch(hideLocationErrorMessage());
    };
};

export const doLocationSearch = (search, google) => {
    return (dispatch, getState) => {
        // TODO FIXME validate the name for invalid characters

        dispatch(fetchLocationLatLng());

        const geocoder = new google.maps.Geocoder();
        var address = search.replace(/, /g, '+');
        geocoder.geocode({ address: address }, (results, status) => {
            if (status === 'OK') {
                const latLng = results[0].geometry.location;
                dispatch(receiveLocationLatLng(latLng));
                dispatch(setMapCenter(latLng, search));
                dispatch(fetchVendorsWithoutService(search, geoUtils.getRadiusBoundingBox(latLng, 20, 20), true));
            } else {
                if (status === 'ZERO_RESULTS') {
                    dispatch(showLocationErrorMessage('No results found for the information entered.'));
                } else {
                    dispatch(status);
                }
                dispatch(showLocationErrorMessage(status));
                dispatch(setReady());
                return;
            }
        });
    };
};

/*==========================================================
                Kingdom Thunk Actions
============================================================ */

/**
 * Fetches available Kingdoms from the backend API.
 * @returns {Function}
 */
export const fetchKingdoms = () => {
    return (dispatch, getState) => {
        if (shouldFetchKingdoms(getState())) {
            return dispatch(_fetchKingdoms());
        } else {
            return Promise.resolve();
        }
    };
};

export const shouldFetchKingdoms = (state) => {
    const kingdoms = state.kingdoms.items;
    if (!kingdoms) {
        return true;
    } else if (kingdoms.isFetching) {
        return false;
    } else {
        return kingdoms.didInvalidate;
    }
};

export const _fetchKingdoms = () => {
    return (dispatch) => {
        dispatch(requestKingdoms());
        return fetch(
            `https://app.health-street.net/api/v1/services/query/kingdom?key=MjIxNzI4RjQtM0RFRi00RjAzLUFGNzYtNEQ5NTRFNTJGMEY2`,
        )
            .then(
                (response) => response.json(),
                (error) => console.log('error fetching kingdoms'),
            )
            .then((json) => {
                dispatch(receiveKingdoms(json));
                json.forEach((kingdom) => {
                    if (!document.kingdom) return;

                    let k = handoffHelper.getKingdomFromDocument();

                    if (k === kingdom.url) {
                        dispatch(selectKingdom(kingdom.id, true));
                    }
                });
            });
    };
};

/*==========================================================
                 Phylum Thunk Actions
============================================================ */

/**
 * Fetches available Phyla from the backend API.
 * @param kingdomId - the kingdom to search for phyla within.
 * @returns {Function}
 */
export const fetchPhyla = (kingdomId) => {
    return function (dispatch, getState) {
        if (shouldFetchPhyla(kingdomId, getState())) {
            return dispatch(_fetchPhyla(kingdomId));
        } else {
            return Promise.resolve;
        }
    };
};

const shouldFetchPhyla = (kingdomId, state) => {
    // console.log('kingdom id', kingdomId);
    const phyla = state.phyla;
    if (!phyla || !phyla[kingdomId]) {
        return true;
    } else if (phyla.received) {
        return false;
    } else {
        return true;
    }
};

const _fetchPhyla = (kingdomId) => {
    return (dispatch) => {
        dispatch(requestPhyla(kingdomId));
        return fetch(
            `https://app.health-street.net/api/v1/services/query/phylum?key=MjIxNzI4RjQtM0RFRi00RjAzLUFGNzYtNEQ5NTRFNTJGMEY2&kingdom=${kingdomId}`,
        )
            .then(
                (response) => response.json(),
                (error) => console.log('error occurred'),
            )
            .then((json) => dispatch(receivePhyla(kingdomId, json)));
    };
};

/*==========================================================
                Family Thunk Actions
============================================================ */

export const fetchFamilies = (phylumId) => {
    return (dispatch, getState) => {
        if (shouldFetchFamilies(getState(), phylumId)) {
            return dispatch(_fetchFamilies(phylumId));
        } else {
            return Promise.resolve();
        }
    };
};

function shouldFetchFamilies(state, phylumId) {
    const families = state.families[phylumId];
    if (!families) {
        return true;
    } else if (families.isFetching) {
        return false;
    } else {
        return families.didInvalidate;
    }
}

export const _fetchFamilies = (phylumId) => {
    return function (dispatch) {
        dispatch(requestFamilies(phylumId));
        return fetch(
            `https://app.health-street.net/api/v1/services/query/family?key=MjIxNzI4RjQtM0RFRi00RjAzLUFGNzYtNEQ5NTRFNTJGMEY2&phylum=${phylumId}`,
        )
            .then(
                (response) => response.json(),
                (error) => console.log('error occurred'),
            )
            .then((json) => dispatch(receiveFamilies(phylumId, json)));
    };
};

/*==========================================================
                Service Thunk Actions
============================================================ */

export const validateSelection = (service, location, history) => {
    //check for unsatisfied modifiers, make sure the vendor provides the service, if all good, confirm vendor.
    return (dispatch, getState) => {
        dispatch(selectService(service));
        fetch(
            `https://app.health-street.net/api/v1/features/query/bodyHair?key=MjIxNzI4RjQtM0RFRi00RjAzLUFGNzYtNEQ5NTRFNTJGMEY2&service=${service.id}`,
        )
            .then(
                (response) => response.json(),
                (error) => dispatch(throwError(error)),
            )
            .then((json) => {
                if (json && json.length > 0) {
                    //just ask every time. Maybe they want to change their choice.
                    dispatch(showModifierDrawer(json));
                    const hist = location.pathname + '/options';
                    dispatch(updateHistory(hist));
                    history.push(hist);
                } else {
                    dispatch(confirmFeatures(service, location, history));
                }
            });
    };
};

const checkService = (service, vendor, bodyHair) => {
    if (!service.requiresFacility || !service.clinicType) {
        return true; //services that don't require facilities, or that don't have a clinicType at all we'll just pass through
    }
    let vendorField = service.clinicType.vendorField;
    vendorField = vendorField.split('::');
    const vendorTable = vendorField[0];
    vendorField = vendorField[1].replace(' ', '').toLowerCase();

    let serviceOffered =
        vendorTable === 'eSites'
            ? vendor.esite && vendor.esite[vendorField]
            : bodyHair
            ? vendor[vendorField] && vendor['bodyhair']
            : vendor[vendorField];
    if (service.healthField) {
        serviceOffered = vendor.esite && vendor.esite.anyhealth && vendor.esite[service.healthField.toLowerCase()];
    }

    if (vendor.sitetype === 'E') {
        serviceOffered = serviceOffered && !!service.eScreenAcct;
    }
    if (vendor.sitetype === 'LC') {
        serviceOffered = serviceOffered && !!service.labCorpProfile;
    }
    if (vendor.sitetype === 'Q') {
        serviceOffered = serviceOffered && !!service.questProfile;
    }
    return serviceOffered;
};

const isServiceOffered = async (service, vendor, selectedFeatures, featuresById, optionsById, dispatch) => {
    let bodyHair = false;
    let realService = service;
    const featureChecker = (option) => {
        if (optionsById[option].bodyHair) {
            bodyHair = true;
        }
        if (optionsById[option].swapVendorSets) {
            //gotta fetch the real service. Is it one of the ones we already have downloaded?
            realService = optionsById[option].serviceId;
        }
    };
    let lowerCaseVendor = Object.keys(vendor).reduce((acc, key) => {
        acc[key.toLowerCase()] = vendor[key];
        return acc;
    }, {});
    if (vendor.eSite) {
        lowerCaseVendor.esite = Object.keys(vendor.eSite).reduce((acc, key) => {
            acc[key.toLowerCase()] = vendor.eSite[key];
            return acc;
        }, {});
    }
    for (let key in selectedFeatures) {
        if (selectedFeatures.hasOwnProperty(key)) {
            if (featuresById[key].bodyHair || featuresById[key].swapVendorSets) {
                selectedFeatures[key].forEach(featureChecker);
            }
        }
    }
    //if our service changed because of a feature set selection, we need to fetch the whole service object.
    if (service !== realService) {
        dispatch(startAltServiceFetch());
        const response = await fetch(
            `https://app.health-street.net/api/v1/services/query/service?key=MjIxNzI4RjQtM0RFRi00RjAzLUFGNzYtNEQ5NTRFNTJGMEY2&id=${realService}`,
        );
        const newService = await response.json();
        dispatch(stopAltServiceFetch());
        dispatch(selectService(newService));
        return checkService(newService, lowerCaseVendor, bodyHair);
    } else {
        console.log('Found an old service');
        return checkService(service, lowerCaseVendor, bodyHair);
    }
};

export const confirmFeatures = (service, location, history) => {
    return (dispatch, getState) => {
        isServiceOffered(
            service,
            getState().order.selectedVendor,
            getState().order.selectedFeatures[service.id],
            getState().featuresById,
            getState().optionsById,
            dispatch,
        ).then((isOffered) => {
            if (isOffered) {
                //handoff to funnel
                dispatch(confirmVendor());
                handoffHelper.handoffToFunnel(getState().order);
            } else {
                dispatch(setVendorInsufficient());
                dispatch(fetchVendorsWithService());
                const hist = '/map/warning';
                dispatch(updateHistory(hist));
                history.push(hist);
            }
        });
    };
};

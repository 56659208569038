import queryString from 'querystring';
import * as constants from '../misc/Constants';

export const handoffToFunnel = (order) => {
    let kingdom, service, vendor, options, place, zip;
    kingdom = order.selectedKingdom;

    if (order.selectedService) {
        service = order.selectedService.id;
    }
    vendor = order.selectedVendor;
    if (order.selectedFeatures) {
        options = order.selectedFeatures;
    }
    place = order.placeId;
    zip = order.zip;

    const origin = constants.appLocation;
    const basePath = '/order';

    let finalQuery = {
        ftrc: 1,
    };

    if (service) {
        finalQuery['service'] = service.toString();
    }
    if (vendor) {
        finalQuery['vendor'] = vendor.vendorId.toString();
    }
    if (options) {
        finalQuery['options'] = JSON.stringify(options);
    }
    if (place) {
        finalQuery['place'] = place.toString();
    }
    if (zip) {
        finalQuery['zip'] = zip.toString();
    }
    if (kingdom) {
        finalQuery['kingdom'] = kingdom.toString();
    }
    finalQuery['origin'] = window.location.href;
    //now leaving FTRC. See ya later suckerzzzz
    const cur = new Date();
    const expires = new Date(
        cur.getFullYear(),
        cur.getMonth(),
        cur.getDate(),
        cur.getHours(),
        cur.getMinutes() + 10,
    );
    document.cookie = 'visited-hs-funnel=true; expires=' + expires.toUTCString();
    window.location.href = encodeURI(origin + basePath) + `?${queryString.stringify(finalQuery)}`;
};

export const getGlassUrl = ({ vendorId, utm_bid, zip }) => {
    let url = 'https://app.health-street.net/order?';
    if (vendorId) url += `vendorId=${vendorId}`;
    if (utm_bid) url += `&utm_bid=${utm_bid}`;
    if (zip) url += `&zip=${zip}`;
    if (document.utm_cid) url += '&utm_cid=' + document.utm_cid;
    if (document.ProductId) url += '&service=' + document.ProductId;
    if (document.PhylumId) url += `&phylum=${document.PhylumId}`;

    let kingdomId;
    if (document.KingdomId) kingdomId = document.KingdomId;
    else {
        kingdomId = getKingdomFromDocument();
        switch (kingdomId) {
            case 'health':
                kingdomId = '3';
                break;
            case 'drug':
                kingdomId = '1';
                break;
            case 'bkgd':
                kingdomId = '4';
                break;
            case 'dna':
                kingdomId = '2';
                break;
            default:
                kingdomId = '1';
                break;
        }
    }

    if (kingdomId) url += `&kingdom=${kingdomId}`;

    return url;
};

export const getKingdomFromDocument = () => {
    if (!document.kingdom) return;

    let k = document.kingdom;
    switch (k) {
        case 'antibody':
        case 'occHealth':
            k = 'health';
            break;
        case 'combined':
        case 'complianceDrugLaw':
        case 'complianceMarijuana':
        case 'drug':
            k = 'drug';
            break;
        case 'background':
        case 'bkgd':
            k = 'bkgd';
            break;
        case 'dna':
            k = 'dna';
            break;
        default:
            k = 'drug';
            break;
    }

    return k;
};

export const getDefaultProductIdForKingdomVendors = () => {
    if (!document.type) return;

    if (document.ProductId) return document.ProductId;

    if (document.type === 'location' && document.kingdom) {
        let k = document.kingdom;

        switch (k) {
            case 'antibody':
            case 'occHealth':
                return 151;

            case 'combined':
            case 'complianceDrugLaw':
            case 'complianceMarijuana':
            case 'drug':
                return 2;

            case 'background':
            case 'bkgd':
                return 172;

            case 'dna':
                return 21;

            default:
                return 2;
        }
    }
};

export const getSearchPrompt = () => {
    if (document.searchPrompt) return document.searchPrompt;

    switch (document.kingdom) {
        case 'antibody':
        case 'occHealth':
            return 'Find workplace health clinics near';
        case 'combined':
        case 'complianceDrugLaw':
        case 'complianceMarijuana':
        case 'drug':
            return 'Find drug test clinics near';
        case 'background':
        case 'bkgd':
            return 'Find background clinics near';
        case 'dna':
            return 'Find DNA testing clinics near';
        default:
            return 'Find Health Street clinics near';
    }
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateHistory } from 'store/actions';
import { selectVendor, unfocusVendor } from '../../store/actions';
import { getGlassUrl } from '../../util/HandoffHelper';

class VendorDetailsPopup extends Component {
    render() {
        const { vendor } = this.props;
        return (
            <div className="vendor-popup" style={{ overflow: 'visible' }}>
                <hr />
                <div className="address-section">
                    <div className="address-lines">
                        <span className="vendor-address-1">{vendor.address}</span>
                        <span className="vendor-address-2">{vendor.address2 ? vendor.address2 : ''}</span>
                        <span className="vendor-city-state-zip">
                            {vendor.city + ', ' + vendor.state + ' ' + vendor.zip}
                        </span>
                    </div>
                </div>
                <hr />
                <div className="vendor-detail-cta" style={{ overflow: 'visible', position: 'relative' }}>
                    <div
                        className="register-button-container"
                        style={{ position: 'relative', overflow: 'visible' }}>
                        <a
                            className="register-button"
                            href={getGlassUrl({
                                vendorId: vendor.vendorId,
                                utm_bid: 'ftrc-map',
                                zip: vendor.zip,
                            })}>
                            Get Tested Here
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.order,
        focusedVendor: state.interface.focusedVendor,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            selectVendor: selectVendor,
            unfocusVendor: unfocusVendor,
            updateHistory: updateHistory,
        },
        dispatch,
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorDetailsPopup);
